export const Experience = () => {
  return (
    <>
      {/* main div */}
      <div id="experience" className="mt-28">
        <h1 className="text-center text-3xl font-bold text-primary">Experience</h1>

        {/* singular div */}
        <div className="m-12 sm:px-28">
          {/* Flex container to manage layout */}
          <div className="flex flex-col">
            {/* Badges will be on top on mobile, and at the end on desktop */}
            <div className="flex space-x-2 mb-2 sm:mb-0 sm:mt-2">
              <div className="badge badge-primary badge-outline">React</div>
              <div className="badge badge-accent badge-outline">Typescript</div>
              <div className="badge badge-warning badge-outline">HTML/CSS</div>
            </div>
            {/* h1 element */}
            <h1 className="font-bold text-lg sm:text-start">
              Front End Developer @ MANGROVIA Blockchain Solutions | 2023 – 2024
            </h1>
          </div>
          <p>
            Contributed to the development of Mangrovia’s nr. 1 product, Datome
            — a Platform-as-a-Service (PaaS) offering enhanced, fully integrated
            solutions for efficient and certified data flow management.
            <br />
            • Building and development of Datome (software) and MBS websites,
            and played a key role in enhancing the Chainkeeper website
            <br />
            • Followed Agile methodologies, including SCRUM processes, utilizing
            Jira and Monday for project management
            <br />
            Technologies: JavaScript, Typescript, React, Redux, Tailwind CSS, Mantine UI, Git, Agile
          </p>
        </div>

        {/* singular div */}
        <div className="m-12 sm:px-28">
          <div className="flex flex-col">
            <div className="flex space-x-2 mb-2 sm:mb-0 sm:mt-2">
              <div className="badge badge-primary badge-outline">React</div>
              <div className="badge badge-accent badge-outline">JavaScript</div>
              <div className="badge badge-warning badge-outline">HTML/CSS</div>
            </div>
            <h1 className="font-bold text-lg sm:text-start">
              Front End Developer @ Freelance | 2022 – 2023
            </h1>
          </div>
          <p>
            Designed and developed websites, e-commerce platforms, web
            applications, mobile apps, and various software solutions for a
            diverse client base.
            <br />
            Tech-stack: JavaScript, React, Angular, Vue.js, Tailwind CSS,
            Wordpress, Shopify, Figma, Canva{" "}
          </p>
        </div>

        {/* singular div */}
        <div className="m-12 sm:px-28">
          <div className="flex flex-col">
            <div className="flex space-x-2 mb-2 sm:mb-0 sm:mt-2">
              <div className="badge badge-primary badge-outline">React</div>
              <div className="badge badge-accent badge-outline">JavaScript</div>
              <div className="badge badge-warning badge-outline">HTML/CSS</div>
            </div>
            <h1 className="font-bold text-lg sm:text-start">
              Front End Developer @ Accenture | 2021 – 2022
            </h1>
          </div>
          <p>
            Maintained a user experience application by fetching, parsing, and
            formatting data, seamlessly integrating it into React components.
            <br />
            • Engineered software architecture and object-oriented code in
            JavaScript, React, HTML, and CSS <br />
            • Executed front-end implementation of CRUD operations, enhancing
            application functionality <br />
            Technologies: React, Redux-saga, JavaScript, Bootstrap,
            styled-components, HTML, CSS, SASS, Git, GitHub, Node, Innersource,
            Zeplin, Jira, Figma{" "}
          </p>
        </div>

        {/* singular div */}
        <div className="m-12 sm:px-28">
          <h1 className="font-bold text-lg">
            Administrative Secretary @ Benefit Srl | 2020 – 2021
          </h1>
          <p>
            Delivered comprehensive administrative and secretarial support,
            including managing phone communications, scheduling appointments,
            organizing documents, overseeing project management tasks, welcoming
            visitors, and maintaining office supplies and equipment.
            <br />
            • Additional responsibilities included data entry and other
            administrative/secretarial duties <br />
            Technologies: Microsoft Office 365, Business Database, Gmail, Google
            Drive{" "}
          </p>
        </div>
      </div>
    </>
  );
};
