import lc from "../media/lc.png";
import resume from "../media/pdf/resume.pdf";

export default function HeroAbout() {
  return (
    <div id="hero" className="hero bg-base-200 min-h-screen pb-16">
      <div className="hero-content flex-col lg:flex-row-reverse">
        <img src={lc} className="max-w-xs rounded-lg shadow-2xl mt-16" alt="myself Laura" />
        <div>
          <h1 className="text-4xl font-bold mt-12">
            Hello, and welcome! 🙋🏻‍♀️💻
          </h1>
          <p className="py-6 sm:pr-32 mt-4">
            I’m Laura Cimpoesu, a passionate Software Engineer specialised in <b>Frontend Web Development</b> and Blockchain Technology. My journey into
            tech was driven by a desire to make a meaningful impact, and I’m
            committed to advancing the role of women in STEM. With strong
            expertise in JavaScript and React, I’m always eager to explore new
            technologies and push the boundaries of what’s possible. My greatest
            accomplishment? Contributing to the world in a way that matters,
            through every line of code I write.
          </p>
          <a className="btn btn-accent" href={resume} download="resume.pdf">
            View my{" "}
            <i>
              <u>Resume</u>{" "}
            </i>
          </a>
        </div>
      </div>
    </div>
  );
}
