import Nav from "./components/Nav";
import HeroAbout from "./components/HeroAbout";
import {Education} from "./components/Education";
import {Experience} from "./components/Experience";
import ProjectList from "./components/ProjectList";
import { Footer } from "./components/Footer";

function App() {
  return (
    <div className="App">
      <Nav/>
      <HeroAbout/>
      <Education/>
      <Experience/> 
      <ProjectList />
      <Footer />
    </div>
  );
}

export default App;
